import { useMediaQuery, useTheme } from "Assets/Material";
import { searchByValue, searchTermValue, useRecoilState } from "Atom";
import { Filters, PageHeader } from "Common";
import DataList from "Modules/AdminBilling/Components/DataList";
import AddTalentManagement from "Modules/TalentManagement/Component/AddTalentManagement";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGetTalentManagementService } from "Services/TalentManagementService";
import { authUser, usertype } from "Utils";
import { SearchFilter, SortFilter } from "Utils/FiltersConstants";


const AdminBilling = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only("xs"));
  const sm = useMediaQuery(theme.breakpoints.only("sm"));

  const serchTermValue = useRecoilState(searchTermValue)
  const searchBy_Value = useRecoilState(searchByValue)

  const history = useHistory();
  const location = useLocation();
  const path = window.location.pathname;

  const initialQueryString = queryString.parse(location.search);
  const initialPageNumber = Number(initialQueryString.page) || 1;

  const [startFrom, setStartFrom] = useState((initialPageNumber - 1) * 10);
  const [endTo, setEndTo] = useState(10);

  const [searchBy, setSearchBy] = useState(searchBy_Value[0]);
  const [searchTerm, setSearchTerm] = useState(serchTermValue[0]);
  const [sortBy, setSortBy] = useState("id");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [sortOrderList, setSortOrderList] = useState([{ id: 1, name: "Default", value: "DESC" }]);

  const [onAddTalentManagement, setOnAddTalentManagement] = useState(false);
  const [isSwapper, setIsSwapper] = useState(false);

  const onSuccesss = (data) => {
    const initialPageNumber = Number(startFrom / 10 + 1) || 1;
    history.push(`${path}?page=${initialPageNumber}`);
  };

  const onError = (error) => {
    console.log({ error });
  };
  const data = {
    from: startFrom,
    offset: 10,
    search_by: searchBy,
    search_term: searchTerm,
    sort_by: sortBy,
    sort_order: sortOrder,
    user_id: authUser()?.user_id,
    user_type: usertype(authUser()?.user_type_id),
    check_total_invoice_count: 1
  };
  const {
    isLoading,
    data: talentManagement,
    isError,
    error,
    refetch,
    isFetching,
  } = useGetTalentManagementService(onSuccesss, onError, data);

  const previous = () => {
    setEndTo(endTo - 10);
    setStartFrom(startFrom - 10);
  };

  const next = () => {
    setEndTo(endTo + 10);
    setStartFrom(startFrom + 10);
  };

  const resetFilters = () => {
    setSearchBy("1");
    setSearchTerm("");
    setSortBy("id");
    setSortOrder("DESC");
    setSortOrderList([{ id: 1, name: "Default", value: "DESC" }]);
  };
  const zeroOne = () => {
    setStartFrom((1 - 1) * 10);
  };
  useEffect(() => {
    document.title = "Invoices";
  }, []);

  return (
    <>
      <PageHeader
        pageTitle={"Invoices"}
      />

      <Filters
        isSearch={true}
        isSort={true}
        searchFilterData={SearchFilter.talentManagement}
        sortFilterData={SortFilter.billingConsultants}
        searchBy={searchBy}
        searchTerm={searchTerm}
        isAddButton={false}
        sortBy={sortBy}
        sortOrder={sortOrder}
        sortOrderList={sortOrderList}
        setSearchBy={(value) => {
          setSearchBy(value);
          zeroOne();
        }}
        setSearchTerm={(value) => {
          setSearchTerm(value);
          zeroOne();
        }}
        setSortBy={(value) => {
          setSortBy(value);
          zeroOne();
        }}
        setSortOrder={(value) => {
          setSortOrder(value);
          zeroOne();
        }}
        setSortOrderList={(value) => {
          setSortOrderList(value);
        }}
        resetFilters={() => {
          resetFilters();
          zeroOne();
        }}
        
      />

      <DataList
        data={talentManagement || []}
        isLoading={isLoading}
        isSwapper={isSwapper}
        onSwapperOpen={() => setIsSwapper(true)}
        onSwapperClose={() => setIsSwapper(false)}
        pagination={{
          perPage: 10,
          from: startFrom + 1,
          to: endTo,
          totalDataItem: talentManagement?.data?.[0]?.count,
          previous: previous,
          next: next,
        }}
      />

      <AddTalentManagement
        onOpen={onAddTalentManagement}
        onClose={() => setOnAddTalentManagement(false)}
        onSwapperClose={() => setIsSwapper(false)}
        // refresh={getTalentManagement}
      />
    </>
  );
};

export default AdminBilling;
