import {
  Grid
} from "Assets/Material";
import { TalentManagementStyles as Styles } from "Assets/Styles";
import { toasterGlobal, useRecoilState } from "Atom";
import { Form, FormButton, TextInput } from "Common";
import { Formik } from "formik";
import { useState } from "react";
import { useAddConsultantService } from "Services/TalentManagementService";
import { emailRegex } from "Utils";

const AddTalentManagement = (props) => {
  const setToaster = useRecoilState(toasterGlobal)[1];

  const [isFormSubmit, setIsFormSubmit] = useState(false);

  const onSuccesss = () => {};
  const onError = (error) => {
    console.log({ error });
  };

  const { mutate } = useAddConsultantService(onSuccesss, onError);

  const validation = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Email is required.";
    } else if (values.email && !emailRegex.test(values.email)) {
      errors.email = "Invalid email address.";
    }

    return errors;
  };

  const onAddConsultant = async (values) => {
    setIsFormSubmit(true);

    mutate(values, {
      onSuccess: async (data) => {
        if (data && data.status === 200) {

          window.open(data.redirect_url, "_blank");
          setIsFormSubmit(false)
          props?.onClose();
           
        } else {
          console.log(data);
          if(data.response.status === 409){
            onCloseAddConsultant(false, "Talent with email id already exists.");
          }else{
            onCloseAddConsultant(false, data.message);
          }
        }
      },
      onError: (error) => {
        console.log("error", error);
        setIsFormSubmit(false)
      },
    });
  };

  const onCloseAddConsultant = (isSuccess, message) => {
    props?.onClose();
    setIsFormSubmit(false);
    setToaster({ isOpen: true, status: isSuccess ? "success" : "fail", message: message });
  };

  return (
    <div >
      <Form  onOpen={props?.onOpen} onClose={props?.onClose} title="Add Consultant">
        <Formik
          initialValues={{
            email: "",
          }}
          validate={(values) => validation(values)}
          onSubmit={(values) => onAddConsultant(values)}
        >
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Grid  container spacing={2}>
                <Grid item xs={12}>
                  <TextInput
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onError={errors.email && touched.email && errors.email}
                  />
                </Grid>
                <Grid item xs={12} sx={Styles.submitButton}>
                  <FormButton disabled={isFormSubmit} label="Add" />
                </Grid>
              </Grid>
            </form>
          )}
        </Formik>
      </Form>
    </div>
  );
};

export default AddTalentManagement;
